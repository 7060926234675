import axios from "axios";
import appConfig from "@/app-config";

export default {
    _token: null,
    _error: null,
    _userId: null,
    _userIdent: null,

    loggedIn() {
        return !!this._token;
    },

    logIn(loginName, password) {

        return axios.post(appConfig.host + "login", {loginname: loginName, pw: password}).then(response => {
            if (response.status === 200) {
                localStorage.setItem('token', response.data.session_token);
                this._token = response.data.session_token;
                this._userId = response.data.user_id;
                this._userIdent = response.data.user_ident;
                appConfig.menu = response.data.menu;
                return {
                    isOk: true
                }
            }
        }).catch(error => {
            this._error = error;
            return {
                isOk: false
            }
        });
    },

    async logOut() {
        this._userIdent = null;
        this._token = null;
        localStorage.removeItem('token');
    },

    async authenticate() {
        const token = localStorage.getItem('token');
        if (!token) return false;

        return await axios.get(appConfig.host + "authenticate?session_token=" + token).then(response => {
            localStorage.setItem('token', response.data.session_token);
            this._token = response.data.session_token;
            this._userId = response.data.user_id;
            this._userIdent = response.data.user_ident;
            appConfig.menu = response.data.menu;
            return response.status === 200;
        }).catch(error => {
            this._error = error;
            return null;
        });
    },

    changePw(pw) {
        return axios.post(appConfig.host + "changePW", {id: this._userId, pw: pw}).then(response => {
            if (response.status === 200) {
                return {
                    isOk: true
                }
            }
        }).catch(error => {
            this._error = error;
            return {
                isOk: false
            }
        });
    }
};
